function init() {
    'use strict';
    //
}

document.addEventListener('DOMContentLoaded', init);
(function ($) {

    $(document).ready(function () {
        $dots = true;
        $asNavFor = null;
        if (document.getElementsByClassName('slider-nav').length > 0) {
            $dots = false;
            $asNavFor = '.slider-nav';
            $('.carousel-block .slider-nav').slick({
                slidesToShow: 6,
                infinite: true,
                slidesToScroll: 1,
                asNavFor: '.carousel-slider',
                dots: false,
                centerMode: true,
                focusOnSelect: true
            });
        }
        $('.carousel-block .carousel-slider').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            adaptiveHeight: true,
            asNavFor: $asNavFor
        });

    });
})(jQuery);
